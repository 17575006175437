/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import "~assets/css/theme.css";
@import "primeng/resources/primeng.css";

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Medium.ttf") format("ttf");
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Regular.ttf") format("ttf");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-SemiBold.ttf") format("ttf");
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

/* ===== Scrollbar CSS ===== */

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--gray-100);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 12px;
  border: 1px solid var(--gray-100);
}


html {
  font-size: var(--font-size-body);
}

body {
  overflow-x: hidden;
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
}

h3 {
  font-size: 18px !important;
  margin: 0 !important;
}

h4 {
  font-size: 16px !important;
  margin: 0 !important;
  font-weight: 600 !important;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: 600;
}

label {
  font-size: var(--font-size-small);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--input-label-color);
  margin-bottom: 8px;
}

.p-calendar {
  width: 100%;
}

.p-button .p-button-label {
  font-weight: 400;
}

.input-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.input-wrapper label {
  display: block;
}

.text-secondary {
  color: var(--body-gray-text-color);
}

.mr-10 {
  margin-right: 10px;
}

.p-button.p-button-text.p-button-plain {
  color: var(--body-text-color);
  background: transparent;
}

.p-button.p-button-text.p-button-plain:enabled:hover {
  color: var(--primary-color);
  background: var(--primary-50);
}

.p-button.p-button-secondary.p-button-outlined {
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover {
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.tabs-button-wrapper .active-button .p-button,
.tabs-button-wrapper .p-button:hover {
  background: var(--primary-50);
  border: 1px solid var(--primary-50);
  color: var(--primary-color);
}

.tabs-button-wrapper .active-button .p-button .p-button-label {
  font-weight: 600;
}

.tabs-button-wrapper .p-button {
  background: #fff;
  border: 1px solid #fff;
  color: var(--body-text-color);
  margin-right: 10px;
}

.tabs-detail-area .p-tabview-panels {
  padding: 0;
}

.p-tabview-nav-content {
  display: none;
}

.table-global-style p {
  margin-bottom: 0;
}

.p-datatable .p-datatable-tbody>tr:hover .signal-icon svg {
  fill: #fff;
  stroke-width: 1px;
  stroke: rgba(255, 255, 255, 0.1);
}

/* 
  Sidebar Panel 
*/
.p-sidebar {
  display: flex;
  width: 500px;
  flex-direction: column;
  justify-content: space-between;
  align-items: self-start;
  height: 100vh;
}

.p-sidebar .p-sidebar-header,
.p-sidebar .p-sidebar-footer,
.p-sidebar .p-sidebar-header+.p-sidebar-content {
  width: 100%;
}

.p-sidebar .p-sidebar-footer {
  display: flex;
  justify-content: space-between;
}

.sidebar-left-padding {
  padding-left: 40px;
}

/* 
    Table Style 
*/
.p-datatable-wrapper {
  border: 1px solid var(--border-color);
  border-radius: 20px;
  max-height: calc(100vh - 176px);
  overflow: auto;
}

.p-datatable .p-datatable-thead {
  position: sticky;
  top: 0;
}

.p-datatable .p-datatable-thead>tr>th {
  border: none;
}

.p-datatable .p-datatable-tbody>tr>td {
  border: 1px solid var(--border-color);
  border-width: 1px 0 0 0;
}

.p-datatable .p-datatable-tbody>tr {
  transition: 0.5s;
}

.p-datatable .p-datatable-tbody>tr:hover {
  background: var(--gray-100);
}

/* 
  Prime Ng UI Update 
*/

/* 
  Login Page css 
*/
.login-sec-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-box-wrapper {
  display: flex;
  width: 350px;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  border-radius: 24px;
  border: 1px solid var(--border-color);
  margin: 32px auto 0 auto;
  max-width: 100%;
  background: #fff;
}

.login-sec-wrapper.organization-login {
  background: #3685fb;
}

.login-box-wrapper .p-button {
  width: 100%;
}

.forgot-pass-link {
  color: var(--body-gray-text-color);
  text-decoration: none;
}

.input-wrapper .show-password {
  position: absolute;
  right: 10px;
  top: 35px;
  color: var(--primary-color);
  cursor: pointer;
}

.password-complexity {
  color: var(--body-gray-text-color);
}

.password-complexity li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.password-complexity .done {
  color: var(--success-green-color);
}

/* Main Dashboard Styles */
main.dashboard-main-wrapper {
  height: 100vh;
  display: flex;
}

aside.main-sidebar {
  width: 280px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--border-color);
  position: relative;
  height: 100vh;
  z-index: 999;
  background: #fff;
}

.sidebar-top-wrap {
  flex-grow: 1;
}

.sidebar-nav {
  height: calc(100vh - 209px);
  overflow: auto;
}

.dasboard-logo-wrap {
  padding: 30px 0 30px 40px;
}

.sidebar-nav ul li a {
  padding: 12px 20px 12px 40px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--body-text-color);
  gap: 12px;
}

.sidebar-nav ul li a.active {
  color: var(--primary-color);
  font-weight: 600;
}

.sidebar-nav ul li a:hover {
  color: var(--primary-color);
}

.sidebar-icon {
  display: flex;
  align-items: center;
}

.sidebar-nav ul li a .sidebar-icon i {
  font-size: 18px;
}

.sidebar-profile-wrap {
  border-top: 1px solid var(--border-color);
}

.sidebar-profile-wrap .sidebar-profile {
  display: flex;
  padding: 32px 40px;
  align-items: center;
}

.profile-image {
  width: 40px;
  height: 40px;
}

.initials-style {
  width: 40px;
  height: 40px;
  background: var(--primary-50);
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.sidebar-prof-detail {
  flex-grow: 1;
  padding: 0 14px;
}

.profile-context-menu {
  width: 100px;
  padding: 5px 0;
  list-style: none;
}

.p-overlaypanel-content ul li a {
  padding: 5px 11px;
  display: block;
  border-bottom: 1px solid var(--border-color);
}

.p-overlaypanel-content ul li:last-child a {
  border-bottom: none;
}

.menu-toggle {
  position: absolute;
  right: -25px;
  width: 25px;
  height: 30px;
  background: #fff;
  border-radius: 0 16px 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  top: 30px;
  border: 1px solid var(--border-color);
  border-left: 0;
}

main.dashboard-main-wrapper .menu-toggle i {
  transition: .5s;
  font-size: 12px;
  color: var(--primary-color);
}

/* 
    Toggled Sidebar 
*/
.main-sidebar.toggled .menu-toggle i {
  transform: rotate(180deg);
}

.main-sidebar.toggled {
  width: 50px;
}

.main-sidebar.toggled .dasboard-logo-wrap {
  padding: 30px 10px 30px 10px;
  text-align: center;
}

.main-sidebar.toggled .sidebar-nav ul li a {
  padding: 12px 10px 12px 10px;
  justify-content: center;
}

.main-sidebar.toggled .sidebar-nav ul li a .menu-label {
  display: none;
}

.main-sidebar.toggled .sidebar-profile-wrap .sidebar-profile {
  display: none;
}

/* 
  Main Content Area Style 
*/
article.main-content-area {
  padding: 32px;
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
}

.content-container {
  max-width: 1100px;
  margin: 0 auto;
}

.search-area {
  display: flex;
  grid-gap: 12px;
  min-width: 350px;
}

.search-area button {
  width: max-content;
}

.signal-icon i {
  font-size: 16px;
  color: var(--primary-color);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.status .p-chip {
  background: var(--success-green-bg);
  color: var(--success-green-color);
  font-size: 13px;
  /* border: 1px solid var(--success-green-color); */
  border: none;
}

.status .p-chip .p-chip-icon {
  font-size: 10px;
}

.status.paused-status .p-chip {
  background: var(--paused-orange-bg);
  color: var(--paused-orange-color);
  /* border: 1px solid var(--paused-orange-color); */
  border: none;
}

.status.danger-status .p-chip {
  background: var(--red-50);
  color: var(--red-500);
  border: none;
}

.signal-detail-col {
  display: flex;
  align-items: center;
  gap: 15px;
}

.signal-icon {
  position: relative;
  width: 40px;
}

.signal-icon svg {
  fill: var(--primary-50);
  stroke-width: 1px;
  stroke: rgba(255, 255, 255, 0.1);
}

/* 
  Sidebar Panel Style 
*/
.slide-panel-body>.signal-detail-col {
  flex-direction: column;
  text-align: center;
  margin-bottom: 30px;
}

.panel-signal-detail {
  margin-top: 30px;
}

.words-listing-wrapper {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  margin-bottom: 20px;
}

.words-listing-wrapper .d-flex {
  width: 100%;
  border-bottom: 1px solid var(--border-color);
}

.words-listing-wrapper .d-flex div {
  padding: 10px 14px;
  flex-grow: 1;
}

.words-listing-wrapper .d-flex div:last-child {
  flex-grow: 0;
}

.words-listing-wrapper .d-flex:last-child {
  border: none;
}

.file-upload-location {
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  background: #f8f9fa;
}

.coming-soon-chip .p-chip {
  background: var(--primary-50);
  font-size: 11px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

/* 
  Create Signal Wrapper 
*/
.create-signal-wrapper {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.create-signal-header {
  padding: 24px 32px;
  border-bottom: 1px solid var(--border-color);
}

.create-signal-body {
  padding: 24px 32px;
  height: calc(100vh - 170px);
  overflow: auto;
}

.create-signal-footer {
  padding: 24px 32px;
  border-top: 1px solid var(--border-color);
}

.signal-cards-outer {
  max-width: 1050px;
  margin: 0 auto;
}

.signal-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
  grid-gap: 16px;
  margin-top: 30px;
  transition: 0.2s;
}

.signal-card {
  padding: 16px;
  border: 2px solid var(--border-color);
  border-radius: 12px;
  transition: 0.5s;
  position: relative;
}

.signal-card:hover {
  border: 2px solid var(--primary-color);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
}

.signal-card.coming-soon-card {
  background: var(--gray-100);
}

.signal-card.coming-soon-card .signal-icon svg {
  fill: #fff;
}

.signal-card .signal-detail-col {
  flex-direction: column;
  align-items: flex-start;
}

.signal-card .p-chip {
  position: absolute;
  right: 16px;
  top: 16px;
  font-size: 11px;
}

/* 
  Create Signal Word Identifier 
*/
.create-signal-identifier {
  max-width: 745px;
  margin: 0 auto;
}

.word-replacement-wrapper {
  margin-top: 30px;
}

.word-replacement-header-wrap {
  display: flex;
  margin-bottom: 20px;
}

.word-replacement-header-wrap>div {
  flex: 1;
}

.original-word.word-replacement {
  color: #ff6b6b;
}

.word-replacement span {
  display: inline-flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ff6b6b;
  color: #fff;
}

.word-replacement span i {
  font-size: 9px;
}

.replaced-word {
  color: #51cf66;
}

.replaced-word span {
  background: #51cf66;
}

.word-replacement-form {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.word-replacement-form .input-wrapper {
  margin: 0;
  flex: 1;
}

.word-replacement-form .pi-arrow-right {
  margin: 0 20px;
}

.word-replacement-form a {
  margin-left: 20px;
}

.word-replacement-form a i {
  font-size: 20px;
}

.word-replacement-body-wrap.word-customization-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
}

.word-customization-col {
  border-radius: 24px;
  border: 1px solid var(--border-color);
  padding: 20px;
}

.word-customization-card-wrapper {
  background: var(--gray-100);
  padding: 50px;
}

.word-customization-card {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 16.667px 29.167px 0px rgba(0, 0, 0, 0.08),
    0px 4.167px 8.333px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(16.66666603088379px);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.word-replace-view {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  justify-content: space-between;
  margin: 20px 0;
}

.orig-word {
  background: #ffe3e3;
  padding: 3px 3px 0 3px;
  border-bottom: 3px solid #ff7171;
}

.replace-word {
  background: #d3f9d8;
  padding: 3px 3px 0 3px;
  border-bottom: 3px solid #5dff73;
}

.word-customization-card .p-button {
  width: 100%;
}

.file-customization-wrapper {
  max-width: 950px;
}

.file-customization-wrapper .word-customization-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.file-customization-wrapper .word-customization-card {
  height: auto;
}

/* 
  Settings Style 
*/
.data-input-wrapper {
  border-radius: 16px;
  border: 1px solid var(--border-color);
}

.data-input-sec {
  padding: 32px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  gap: 150px;
}

.data-input-field-sec {
  flex-grow: 1;
}

.data-input-wrapper .data-input-sec:last-child {
  border-bottom: none;
}

.blue-bg-sec {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: var(--primary-50);
  color: var(--primary-color);
  margin-bottom: 25px;
}

.slide-panel-body>.signal-detail-col .profile-image {
  width: 96px;
  height: 96px;
  position: relative;
  margin: 0 auto 25px auto;
  font-size: 34px;
}

.slide-panel-body>.signal-detail-col .profile-image img {
  width: 100%;
}

.slide-panel-body>.signal-detail-col .profile-image .p-chip {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translate(-50%, 0);
}

.top-users-wrapper .words-listing-wrapper .flex-grow-1 div:last-child,
.top-users-wrapper .words-listing-wrapper .flex-grow-1 .profile-image {
  padding: 0;
}

.top-users-wrapper .words-listing-wrapper .flex-grow-1 .profile-image {
  width: 20px;
  height: 20px;
  font-size: 10px;
}

.top-users-wrapper .words-listing-wrapper .profile-image img {
  width: 100%;
}

.top-users-wrapper .words-listing-wrapper .signal-info p {
  margin: 0;
}

.invalid-email {
  color: red;
}

.valid-input {
  border: 1px solid #ced4da;
  /* or any other color for valid input */
}

.caption-none {
  display: none;
}

.theme-green .bs-datepicker-head {
  background-color: #0066ff !important;
}

.bs-datepicker-head {
  background-color: #0066ff !important;
}

.theme-green .bs-datepicker-body table td.week span {
  color: #0066ff !important;
}

.active {
  --tw-bg-opacity: 1;
  /* Text - Medium */
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: none !important;
}

.sidebar-nav .active {
  background-color: unset !important;
  line-height: unset !important;
}

.drawer-open {
  overflow-y: hidden;
}

.icon-badge {
  width: 20px;
  height: 20px;
  display: inline-flex;
  background: #69db7c;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-right: 5px;
}

.icon-badge .pi {
  font-size: 11px;
}

.icon-badge.reject-badge {
  background: #FF6B6B;
}

.cursor-pointer {
  cursor: pointer;
}

.border-red {
  border: 1px solid red;
}

/* 
  Projects Style 
*/
.create-project-form-wrapper {
  max-width: 550px;
  margin: 0 auto;
}

.font-size-large {
  font-size: 18px;
}

.project-settings-wrapper .data-input-sec {
  justify-content: space-between;
}

.project-settings-wrapper .data-input-field-sec {
  max-width: 650px;
}

.client-extension-wrapper .data-input-sec {
  display: block;
  padding: 20px;
}

/* 
  PHI and PII screens 
*/
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem 1.25rem;
  border: none;
  color: #000;
  background: #fff;
  font-weight: 600;
  border-radius: 16px;
  transition: box-shadow 0.15s;
  text-decoration: none;
  font-size: 18px;
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #fff;
  color: #000;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none;
}

.p-accordion .p-accordion-content {
  border: none;
}

.p-accordion p-accordiontab .p-accordion-tab {
  border-bottom: 1px solid var(--border-color);
}

.p-accordion p-accordiontab:last-child .p-accordion-tab {
  border-bottom: none;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  order: 1;
  margin-right: 0;
  margin-left: 10px;
}

.accordion-badge {
  margin-left: auto;
  background: var(--primary-50);
  border: 1px solid var(--primary-50);
  color: var(--primary-color);
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 20px;
}

.acc-head-desc {
  font-size: 13px !important;
}

.api-var-placeholder {
  background: var(--border-color);
  padding: 5px 5px;
}

.p-toggleable-content h4 {
  color: var(--primary-color);
  margin-bottom: 10px !important;
}

.category-wrapper {
  padding: 0 20px 10px 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
}

.category-wrapper:last-child {
  margin: 0;
  padding-bottom: 0;
  border: none;
}

.category-wrapper ul {
  list-style: none;
}

.category-wrapper ul {
  list-style: none;
}

.category-wrapper ul li {
  position: relative;
}

.category-wrapper ul li::before {
  content: "\2022";
  position: absolute;
  left: -25px;
  font-size: 24px;
  color: var(--primary-color);
  line-height: 1;
}

.category-wrapper ul li h4 {
  color: #000;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.section-header-wrap {
  flex-wrap: wrap;
  gap: 10px;
}

/* 
  Responsive Style 
*/
@media (max-width: 1024px) {
  aside.main-sidebar {
    width: 50px;
  }


  .main-sidebar .dasboard-logo-wrap {
    padding: 30px 10px 30px 10px;
    text-align: center;
  }


  .main-sidebar .sidebar-nav ul li a {
    padding: 12px 10px 12px 10px;
    justify-content: center;
  }


  .main-sidebar .sidebar-nav ul li a .menu-label {
    display: none;
  }


  .main-sidebar .sidebar-profile-wrap .sidebar-profile {
    display: none;
  }

  .data-input-sec {
    gap: 50px;
  }
}

@media (max-width: 767px) {
  .menu-toggle {
    display: none;
  }

  article.main-content-area {
    padding: 32px 15px;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem 1.85rem 1rem 1.25rem;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 14px;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    position: absolute;
    right: 10px;
  }

  .data-input-sec {
    gap: 30px;
    flex-wrap: wrap;
  }

  .search-area {
    min-width: 290px;
    flex-wrap: wrap;
    justify-content: end;
  }

  .category-wrapper {
    padding: 0 10px 10px 10px;
  }

  .acc-head-desc {
    font-size: 11px !important;
  }

  .accordion-badge {
    border-radius: 20px;
  }

  .p-toggleable-content h4 {
    font-size: 14px !important;
  }

  .p-toggleable-content {
    font-size: 12px;
  }
}